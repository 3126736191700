import React from "react";
import { Grid, Paper } from "@material-ui/core";

import "./Login.css";

function Login({ authenticated }) {
  const client_id =
    window.location.href.indexOf("localhost") !== -1
      ? "4064522uk8ww3b6z"
      : "405opg30kioxl3cm";
  const redirect_uri =
    window.location.href.indexOf("localhost") !== -1
      ? "http://localhost:3000/auth/credo/cb"
      : "https://tommy-4e603.firebaseapp.com/auth/credo/cb";
  return (
    <Grid container alignItems="center" justify="center">
      <Grid item md={3}>
        <Paper className="loginWrapper">
          <h1>FantasyPi</h1>
          <a
            href={`https://usecredo.com/api/oauth2/authorize?response_type=token&redirect_uri=${encodeURIComponent(
              redirect_uri
            )}&client_id=${client_id}`}
          >
            Login With Credo
          </a>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Login;
