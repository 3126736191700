import React, { useEffect, useState } from "react";
import axios from "axios";
import createPersistedState from "use-persisted-state";
import { motion, useAnimation } from "framer-motion";
import { AuthContext } from "./Auth";
import Modal from "react-modal";
import ReactJson from "react-json-view";

const useApiHost = createPersistedState("api_host");
const useEndpointsSource = createPersistedState("endporints_source");

const DataSourceContex = React.createContext({ fetcher: null });

type FpiEndpoint = "/add_portfolio" | "/games" | "/game/:id";

type EndpointDescription = {
  [key in FpiEndpoint]?: {
    exampleRequest?: object;
    exampleResponse: object;
  };
};

type EnpointSource = {
  [key: string]: "JSON" | "NET";
};

const post_endpoints: EndpointDescription = {
  "/add_portfolio": {
    exampleRequest: {
      game_id: 1,
      portfolio_name: "wild_wind_17",
    },
    exampleResponse: {
      portfolio_id: 10,
    },
  },
};

const get_endpoints: EndpointDescription = {
  "/games": {
    exampleResponse: {
      public_games: [
        {
          name: "Week 15 All In",
          type: "all",
          fee: 20,
          registered_players: 20,
        },
        {
          name: "Week 15 All In XXL",
          type: "all",
          fee: 200,
          registered_players: 3,
        },
        {
          name: "Week 15 Big Boys",
          type: "s&p500",
          fee: 20,
          registered_players: 9,
        },
        {
          name: "Week 15 Terminators",
          type: "tech",
          fee: 20,
          registered_players: 13,
        },
        {
          name: "Week 15 Hot Wheels",
          type: "automotive",
          fee: 20,
          registered_players: 10,
        },
      ],
      joined_games: [
        {
          name: "Week 14 All In",
          type: "all",
          fee: 20,
          registered_players: 4,
          id: 2,
        },
      ],
    },
  },
  "/game/:id": {
    exampleResponse: {
      portfolios: {
        Name: {
          content: [{ name: "Microsoft", percentage: 20 }],
        },
      },
      companies: {
        Microsoft: {
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Microsoft_logo_-_2012_%28vertical%29.svg/1910px-Microsoft_logo_-_2012_%28vertical%29.svg.png",
          name: "Microsoft",
        },
        Apple: {
          img:
            "https://media.idownloadblog.com/wp-content/uploads/2018/07/Apple-logo-black-and-white.png",
          name: "Apple",
        },
      },
      leaderboard: [
        { name: "Louis Alecu", win: 175 },
        { name: "Ralph ROgobete", win: 141 },
        { name: "Andrei Macaneata", win: 15 },
        { name: "Alexandru Niculae", win: -44 },
      ],
    },
  },
};

function DataSourceProvider({ children }) {
  const { token } = React.useContext(AuthContext);
  const [modal, setModal] = useState<object | null>(null);

  const [apiHost, setApiHost] = useApiHost<string>();
  const [
    endpointsSource,
    setEndpointsSource,
  ] = useEndpointsSource<EnpointSource>({});

  const animation = useAnimation();

  useEffect(() => {
    Object.keys(get_endpoints).forEach((k) => {
      if (endpointsSource[`GET ${k}`] === undefined) {
        setEndpointsSource((prev) => ({ ...prev, [`GET ${k}`]: "JSON" }));
      }
    });
    Object.keys(post_endpoints).forEach((k) => {
      if (endpointsSource[`POST ${k}`] === undefined) {
        setEndpointsSource((prev) => ({ ...prev, [`POST ${k}`]: "JSON" }));
      }
    });
  }, []);

  function getSourceAndKeyForUrl(
    url: FpiEndpoint
  ): ["JSON" | "NET", FpiEndpoint] {
    let s: "JSON" | "NET";
    let key: FpiEndpoint;
    Object.entries(endpointsSource).forEach(([ep, source]) => {
      const path = ep.split(" ")[1];
      if (path === url) {
        s = source;
        key = path as FpiEndpoint;
      } else {
        const pathParts = path.split("/");
        const urlParts = url.split("/");
        if (pathParts.length === urlParts.length) {
          let ok = true;
          for (let i = 0; i < pathParts.length; i++) {
            if (pathParts[i] !== urlParts[i] && pathParts[i][0] !== ":") {
              ok = false;
              break;
            }
          }
          if (ok) {
            s = source;
            key = path as FpiEndpoint;
          }
        }
      }
    });
    return [s, key];
  }

  const fetcher = {
    post: async (url: FpiEndpoint, data: object, opt: object): Promise<any> => {
      const [source, key] = getSourceAndKeyForUrl(url);
      if (source === "JSON") {
        return new Promise((r) =>
          r({ data: post_endpoints[key].exampleResponse })
        );
      } else {
        const res = await axios.post(`${apiHost}/${url}`, data, opt);
        console.log(`==============POST ${url}==========`);
        console.log(res?.data?.data);
        console.log("====================================");
        return res?.data?.data;
      }
    },
    get: async (url: FpiEndpoint, opt: object): Promise<any> => {
      const [source, key] = getSourceAndKeyForUrl(url);
      if (source === "JSON") {
        return new Promise((r) =>
          r({ data: get_endpoints[key].exampleResponse })
        );
      } else {
        const res = await axios.get(`${apiHost}/${url}`, opt);
        console.log(`==============GET ${url}==========`);
        console.log(res?.data?.data);
        console.log("====================================");
        return res?.data?.data;
      }
    },
  };

  function updateEndpointSource(endpoint: string, value: string): void {
    setEndpointsSource(
      (prev) =>
        ({
          ...prev,
          [endpoint]: value,
        } as EnpointSource)
    );
  }

  return (
    <DataSourceContex.Provider value={{ fetcher }}>
      <Modal isOpen={modal !== null} onRequestClose={() => setModal(null)}>
        <div>
          <ReactJson src={modal} />
        </div>
      </Modal>
      <div
        className="data-source"
        onMouseEnter={() =>
          animation.start({ height: "auto", width: "auto", display: "block" })
        }
        onMouseLeave={() =>
          animation.start({ height: 0, width: 0, display: "none" })
        }
      >
        Settings
        <motion.div
          animate={animation}
          style={{ height: "0px", display: "none", zIndex: 100 }}
          className="data-source-details"
        >
          <div>
            Credo Token
            <div style={{ width: "200px", wordWrap: "break-word" }}>
              {token}
            </div>
            <hr />
            <small>API BASE</small>
            <br />
            <input
              value={apiHost}
              onChange={(e) => setApiHost(e.target.value)}
            />
          </div>
          <div>
            <small>POST Endpoints</small>
            <br />
            <ul>
              {Object.entries(post_endpoints).map(([e, v]) => (
                <li>
                  {e}
                  <input
                    type="radio"
                    value="NET"
                    checked={endpointsSource[`POST ${e}`] === "NET"}
                    onChange={(ev) =>
                      updateEndpointSource(`POST ${e}`, ev.target.value)
                    }
                  />
                  <label>API</label>
                  <input
                    type="radio"
                    value="JSON"
                    checked={endpointsSource[`POST ${e}`] === "JSON"}
                    onChange={(ev) =>
                      updateEndpointSource(`POST ${e}`, ev.target.value)
                    }
                  />
                  <label>JSON</label>
                  <span onClick={() => setModal(v)}>View JSON</span>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <small>GET Endpoints</small>
            <br />
            <ul>
              {Object.entries(get_endpoints).map(([e, v]) => (
                <li>
                  {e}
                  <input
                    type="radio"
                    value="NET"
                    checked={endpointsSource[`GET ${e}`] === "NET"}
                    onChange={(ev) =>
                      updateEndpointSource(`GET ${e}`, ev.target.value)
                    }
                  />
                  <label>API</label>
                  <input
                    type="radio"
                    value="JSON"
                    checked={endpointsSource[`GET ${e}`] === "JSON"}
                    onChange={(ev) =>
                      updateEndpointSource(`GET ${e}`, ev.target.value)
                    }
                  />
                  <label>JSON</label>
                  <span onClick={() => setModal(v)}>View JSON</span>
                </li>
              ))}
            </ul>
          </div>
        </motion.div>
      </div>
      {children}
    </DataSourceContex.Provider>
  );
}

export { DataSourceContex, DataSourceProvider };
