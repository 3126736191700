import React from "react";
import { produce } from "immer";
import axios from "axios";
import { AuthContext } from "../Auth";
import { DataSourceContex } from "../DataSource";

type AnyObject = {
  [key: string]: any;
};
const anyobj: AnyObject = {};
const DashboardServiceContex = React.createContext(anyobj);

const API_HOST = "http://ec2-3-10-116-128.eu-west-2.compute.amazonaws.com:8000";

const reducer = (state: any, action: any) =>
  produce(state, (draft: any) => {
    switch (action.type) {
      case "INIT":
        draft = action.payload;
        return draft;
      case "JOIN_GAME":
        const { idx } = action.payload;
        const game = draft.public_games.splice(idx, 1)[0];
        console.log(game);
        draft.joined_games.push(game);
        return draft;
      case "CREATE_GAME":
        const { name, type, fee } = action.payload;
        draft.joined_games.push({
          name,
          type,
          fee,
          registered_players: 1,
        });
        return draft;
      default:
        throw Error("Unknown action type.");
    }
  }) as any;

function DashboardServiceProvider({ children }) {
  const { user, token } = React.useContext(AuthContext);
  const { fetcher } = React.useContext(DataSourceContex);
  const [data, dispatch] = React.useReducer(reducer, { loading: true });

  React.useEffect(() => {
    async function loadDataFromApi() {
      console.log("Gettign from api....");
      const res = await fetcher.get(`/games`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res.data);
      if (res.data) {
        dispatch({
          type: "INIT",
          payload: { loading: false, ...res.data },
        });
      }
    }
    loadDataFromApi();
  }, [token]);

  async function joinGame(idx) {
    const req = {
      game_id: data.public_games[idx].id,
      portfolio_name: "First portofolio",
      Authorization: `Bearer ${token}`,
    };
    console.log(req);
    const res = await axios.post(
      `${API_HOST}/add_portfolio`,
      {},
      {
        headers: req,
      }
    );
    console.log(res);
    if (res) {
      dispatch({
        type: "JOIN_GAME",
        payload: { idx },
      });
    }
  }

  function createGame(name, type, fee) {
    dispatch({
      type: "CREATE_GAME",
      payload: {
        name,
        type,
        fee,
      },
    });
  }

  return (
    <DashboardServiceContex.Provider value={{ ...data, joinGame, createGame }}>
      {children}
    </DashboardServiceContex.Provider>
  );
}

export { DashboardServiceContex, DashboardServiceProvider };
