import React from "react";
import { GameServiceContext } from "./GameService";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Modal,
} from "@material-ui/core";
import { CSSTransition } from "react-transition-group";
import { makeStyles } from "@material-ui/core/styles";

const modalStyle = {
  top: `50%`,
  left: `50%`,
  height: "80%",
  width: "80%",
  transform: `translate(-${50}%, -${50}%)`,
  "overflow-y": "scroll",
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  createPrivateGameButton: {
    marginTop: "10px",
  },
}));

function CompaniesSelectModal({
  companies,
  addToPortofolio,
  open,
  setCompaniesModalIsOpened,
}) {
  const [search, setSearch] = React.useState();
  const [company, setCompany] = React.useState(null);
  const [percentage, setPercentage] = React.useState(20);
  const [lastAdded, setLastAdded] = React.useState(null);

  const classes = useStyles();
  const companies_matching_search = Object.keys(companies)
    .sort()
    .filter(
      (k) =>
        search == null ||
        companies[k].name.toLowerCase().startsWith(search.toLowerCase())
    );

  return (
    <Modal
      open={open}
      onClose={() => {
        setCompaniesModalIsOpened(false);
        setLastAdded(null);
        setCompany(null);
      }}
    >
      <div style={modalStyle} className={classes.paper}>
        <CSSTransition
          in={company === null}
          timeout={300}
          classNames="ctg-table"
        >
          <div className="ctg-table">
            <div>
              <br />
              {lastAdded && (
                <div>
                  <div className="message-area">
                    {lastAdded.name} successfully added to portofolio.
                  </div>
                  <br />
                </div>
              )}
              <TextField
                onChange={(e) => setSearch(e.target.value)}
                variant="outlined"
                fullWidth
                value={search}
                label="Seach Company"
              />
            </div>
            <br />
            {companies_matching_search.length === 0 && (
              <p className="message-area">No companies results.</p>
            )}
            {companies_matching_search.length > 0 && (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Last Week Delta</TableCell>
                      <TableCell>Last Month Delta</TableCell>
                      <TableCell>Last 6 Months Delta</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companies_matching_search.map((k, idx) => (
                      <TableRow key={idx}>
                        <TableCell>
                          <img src={companies[k].img} height="20" alt={k} />
                        </TableCell>
                        <TableCell>{companies[k].name}</TableCell>
                        <TableCell>{companies[k].value}$</TableCell>
                        <TableCell>{companies[k].l1w}%</TableCell>
                        <TableCell>{companies[k].l1m}%</TableCell>
                        <TableCell>{companies[k].l6m}%</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setLastAdded(null);
                              setCompany({ ...companies[k] });
                            }}
                          >
                            Add to Portofolio
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </CSSTransition>
        <CSSTransition
          in={company !== null}
          timeout={300}
          classNames="ctg-percentage"
        >
          <div className="ctg-percentage">
            {company && (
              <Grid container>
                <Grid item xs={4} style={{ padding: "50px" }}>
                  <img src={company.img} style={{ maxWidth: "100%" }} />
                </Grid>
                <Grid item xs={8}>
                  <h1>{company.name}</h1>
                  <small>{company.ticker}</small>
                  <br />
                  <br />
                  <TextField
                    type="number"
                    fullWidth
                    value={percentage}
                    label={`Select percentage of portofolio to invest in ${company.name}`}
                    onChange={(e) => {
                      // if (
                      //   e.target.value > 0 &&
                      //   e.target.value < 100 &&
                      //   Number.isInteger(e.target.value)
                      // )
                      {
                        setPercentage(Number(e.target.value));
                      }
                    }}
                  />
                  <br />
                  <br />
                  <Button
                    onClick={() => {
                      setCompany(null);
                      setSearch("");
                      setLastAdded(company);
                      addToPortofolio(company.name, percentage);
                    }}
                  >
                    Add To Portofolio
                  </Button>
                  &nbsp;
                  <Button
                    onClick={() => {
                      setCompany(null);
                      setSearch("");
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        </CSSTransition>
      </div>
    </Modal>
  );
}

export default CompaniesSelectModal;
