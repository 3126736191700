import React from "react";
import { DashboardServiceContex } from "./DashboardService";
import GameTable from "./GameTable";
import { Button, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const modalStyle = {
  top: `20%`,
  left: `50%`,
  transform: `translate(-${50}%, -${20}%)`,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  createPrivateGameButton: {
    marginTop: "10px",
  },
}));

function PublicGames() {
  const classes = useStyles();
  const [idx, setIdx] = React.useState(-1);
  const { loading, public_games, joinGame } = React.useContext(
    DashboardServiceContex
  );
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ width: "100%" }}>
      {idx != -1 && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <h2>Join Game</h2>
            <p>
              You are about to join <b>{public_games[idx].name}</b> game, where
              portfolie price is <b>{public_games[idx].fee}$</b>.
            </p>
            <Button
              onClick={() => {
                joinGame(idx);
                handleClose();
              }}
            >
              Confirm
            </Button>
            &nbsp;
            <Button
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      )}
      <h2>Public Games</h2>

      {loading && <div className="animated-background"></div>}

      {!loading && public_games.length > 0 && (
        <GameTable
          games={public_games}
          actions={["Join"]}
          onAction={(game, idx, action, e) => {
            setIdx(idx);
            handleOpen();
          }}
        ></GameTable>
      )}
      {!loading && public_games.length === 0 && (
        <p className="message-area">No public games available</p>
      )}
    </div>
  );
}

export default PublicGames;
