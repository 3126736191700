import React from "react";
import { useHistory } from "react-router-dom";
import { DashboardServiceContex } from "./DashboardService";
import GameTable from "./GameTable";

function JoinedGames() {
  const { joined_games, loading } = React.useContext(DashboardServiceContex);
  const history = useHistory();

  return (
    <div style={{ width: "100%" }}>
      <h2>Joined Games</h2>
      {loading ? (
        <div className="animated-background"></div>
      ) : (
        <GameTable
          games={joined_games}
          actions={["View"]}
          onAction={(game, idx, action, e) => {
            if (action === "View") {
              history.push(`/game/${game.id}`);
            }
          }}
        />
      )}
    </div>
  );
}

export default JoinedGames;
