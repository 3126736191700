import React, { useContext } from "react";
import { produce } from "immer";
import { useParams } from "react-router-dom";
import { AuthContext } from "../Auth";
import { DataSourceContex } from "../DataSource";

const API_HOST = "http://ec2-3-10-116-128.eu-west-2.compute.amazonaws.com:8000";

type AnyObject = {
  [key: string]: any;
};
const anyobj: AnyObject = {};
const GameServiceContext = React.createContext(anyobj);

type ReducerAction = {
  type: string;
  payload?: any;
};

const reducer = (state, action: ReducerAction) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "INIT":
        draft = { loading: false, ...action.payload };
        return draft;
      case "INIT_PORTFOLIOS":
        draft.portfolios = action.payload;
        return draft;
      case "INIT_COMPANIES":
        draft.companies = action.payload;
        return draft;
      case "DONE":
        draft.loading = false;
        return draft;
      case "CREATE_NEW_PORTFOLIO":
        const { name } = action.payload;
        draft.portfolios[name] = {
          content: [],
        };
        return draft;
      case "ADD_TO_PORTOFOLIO": {
        const { name, percentage, portfolio_name } = action.payload;
        draft.portfolios[portfolio_name].content.push({
          name,
          percentage,
        });
        draft.companies[name].available = false;
        return draft;
      }
      case "REMOVE_FROM_PORTOFOLIO": {
        const { name, portfolio_name } = action.payload;
        const items = draft.portfolios[portfolio_name].content.filter(
          (c) => c.name === name
        );
        if (items.length !== 1) {
          throw Error("Incosistent portofolio.");
        }
        const company_idx = draft.portfolios[portfolio_name].content.indexOf(
          items[0]
        );
        draft.portfolios[portfolio_name].content.splice(company_idx, 1);
        draft.companies[name].available = true;
        return draft;
      }
      default:
        throw Error("Unknown action");
    }
  }) as any;

interface Portfolio {
  content: {
    name: string;
    percentage: number;
  };
}

interface Leaderboard {
  name: string;
  win: number;
}

interface Company {
  img: string;
  name: number;
  ticker: string;
}

interface GameServieState {
  loading: boolean;
  portfolios: {
    [key: string]: Portfolio;
  };
  companies: {
    [key: string]: Company;
  };
  leaderboard: Leaderboard[];
}

function GameServiceProvider({ children }) {
  const { id } = useParams();
  const { token } = React.useContext(AuthContext);
  const { fetcher } = useContext(DataSourceContex);

  const [game, dispatch] = React.useReducer(reducer, {
    loading: true,
    portfolios: [],
  });

  React.useEffect(() => {
    async function loadDataFromApi(id) {
      const res = await fetcher.get(`/game/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res) {
        dispatch({
          type: "INIT",
          payload: { ...res.data },
        });
      }
    }
    loadDataFromApi(id);
  }, [id, token]);

  async function buyPortfolio(name: string) {
    dispatch({
      type: "CREATE_NEW_PORTFOLIO",
      payload: { name },
    });
  }

  function addToPortofolio(name, percentage, portfolio) {
    dispatch({
      type: "ADD_TO_PORTOFOLIO",
      payload: {
        name,
        percentage,
        portfolio_name: portfolio.name,
      },
    });
  }

  function removeFromPortofolio(name, portfolio) {
    dispatch({
      type: "REMOVE_FROM_PORTOFOLIO",
      payload: { name, portfolio_name: portfolio.name },
    });
  }

  return (
    <GameServiceContext.Provider
      value={{
        ...game,
        addToPortofolio,
        removeFromPortofolio,
        buyPortfolio,
      }}
    >
      {children}
    </GameServiceContext.Provider>
  );
}

export { GameServiceContext, GameServiceProvider };
