import React from "react";
import Haikunator from "haikunator";
import { Button, TextField, Modal } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const modalStyleSmall = {
  top: `20%`,
  left: `50%`,
  width: "400px",
  transform: `translate(-${50}%, -${20}%)`
};

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  createPrivateGameButton: {
    marginTop: "10px"
  }
}));

function BuyPortfolioModal({ open, buyPortfolio, handleClose }) {
  const classes = useStyles();
  const name = new Haikunator().haikunate();

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyleSmall} className={classes.paper}>
        <h2>Buy Portfolio</h2>
        You are about to buy a new portofolio in game $NAME for $PRICE$.
        <br />
        <br />
        <TextField label="Portofolio name" value={name} fullWidth disabled />
        <br />
        <br />
        <Button
          onClick={() => {
            buyPortfolio(name);
          }}
        >
          Confirm
        </Button>
        &nbsp;
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}

export default BuyPortfolioModal;
