import React from "react";
import axios from "axios";
import { getCookie, setCookie, eraseCookie } from "./cookie_utils";

type AnyObject = {
  [key: string]: any;
};
const anyobj: AnyObject = {};
const AuthContext = React.createContext(anyobj);

function AuthProvider({ children }) {
  const [data, setData] = React.useState({
    resolving: true,
    authenticated: false,
    user: null,
    token: "",
  });

  if (window.location.href.split("token=").length > 1) {
    setCookie(
      "token",
      window.location.href.split("token=")[1].split("&")[0],
      365
    );
    window.location.replace("/");
  }

  React.useEffect(() => {
    async function checkAuth() {
      const res = await axios
        .get("https://usecredo.com/user", {
          headers: {
            Authorization: `Bearer ${getCookie("token")}`,
          },
        })
        .catch((e) => {
          setData({
            resolving: false,
            authenticated: false,
            user: null,
            token: "",
          });
        });
      if (res) {
        setData({
          resolving: false,
          authenticated: true,
          user: res.data,
          token: getCookie("token") as string,
        });
      }
    }
    checkAuth();
  }, []);

  function logout() {
    eraseCookie("token");
    window.location.reload();
  }

  return (
    <AuthContext.Provider value={{ ...data, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
