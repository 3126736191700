import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Modal
} from "@material-ui/core";

function PortofolioItem({ item, onClick, btnText }) {
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h3">
          {item.name}
        </Typography>
        {item.percentage}%
      </CardContent>
      <img alt="company evolution" height="100" src={item.img} />
      <CardActions>
        <Button size="small" color="primary" onClick={onClick}>
          {btnText}
        </Button>
      </CardActions>
    </Card>
  );
}

export default PortofolioItem;
