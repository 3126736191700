import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
} from "@material-ui/core";

function GameTable({ games, actions, onAction }) {
  console.log(games);
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Game Name</TableCell>
            <TableCell>Available Companies</TableCell>
            <TableCell>Portofolio Value</TableCell>
            <TableCell>Total Win</TableCell>
            <TableCell>Total Players</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {games.map((row, idx) => (
            <TableRow key={idx}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>{row.fee}$</TableCell>
              <TableCell>{row.fee * row.portfolios_cnt}$</TableCell>
              <TableCell>{row.users_cnt}</TableCell>
              <TableCell>
                {actions.map((action) => (
                  <Button
                    key={action}
                    onClick={(e) => onAction(row, idx, action, e)}
                  >
                    {action}
                  </Button>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GameTable;
