import React from "react";
import { AuthContext } from "../Auth";
import { GameServiceProvider } from "./GameService";
import { Grid, Button } from "@material-ui/core";
import Header from "./../Header";
import Portofolios from "./Portofolios";
import Leaderboard from "./Leaderboard";

import "./Game.css";

function Game() {
  const { user, logout } = React.useContext(AuthContext);
  const [tab, setTab] = React.useState(0);

  const tab_names = ["My Portofolios", "Leaderboard"];

  return (
    <div className="dashboardWrapper">
      <GameServiceProvider>
        <Grid container>
          <Grid container>
            <Header username={user.username} onLogout={logout} />
          </Grid>
          <Grid container className="tabs">
            {tab_names.map((t, idx) => {
              if (idx === tab) {
                return (
                  <Button key={idx} variant="contained" color="primary">
                    {t}
                  </Button>
                );
              } else {
                return (
                  <Button key={idx} onClick={() => setTab(idx)}>
                    {t}
                  </Button>
                );
              }
            })}
          </Grid>

          <Grid container>
            {tab === 0 && <Portofolios />}
            {tab === 1 && <Leaderboard />}
          </Grid>
        </Grid>
      </GameServiceProvider>
    </div>
  );
}

export default Game;
