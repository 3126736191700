import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { AuthProvider, AuthContext } from "./Auth";
import LinearProgress from "@material-ui/core/LinearProgress";
import Login from "./Login";
import Dashboard from "./Dashboard";
import Game from "./Game";
import "./App.css";
import { DataSourceProvider } from "./DataSource";

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function AppRouter() {
  const { authenticated, resolving } = React.useContext(AuthContext);

  if (resolving) {
    return <LinearProgress />;
  }

  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />

        <PrivateRoute
          path="/dashboard"
          component={Dashboard}
          authenticated={authenticated}
        />

        <PrivateRoute
          path="/game/:id"
          component={Game}
          authenticated={authenticated}
        />

        <Route
          exact
          path="/login"
          component={() => <Login authenticated={authenticated} />}
        />
      </Switch>
    </Router>
  );
}

function App() {
  return (
    <AuthProvider>
      <DataSourceProvider>
        <AppRouter />
      </DataSourceProvider>
    </AuthProvider>
  );
}

export default App;
