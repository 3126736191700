import React from "react";
import { DashboardServiceContex } from "./DashboardService";
import {
  Button,
  Modal,
  TextField,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const modalStyle = {
  top: `20%`,
  left: `50%`,
  transform: `translate(-${50}%, -${20}%)`
};

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  createPrivateGameButton: {
    marginTop: "10px"
  }
}));

function CreatePrivateGame() {
  const classes = useStyles();
  const { createGame } = React.useContext(DashboardServiceContex);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState();
  const [type, setType] = React.useState("all");
  const [fee, setFee] = React.useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2>Create Private Game</h2>
          <TextField
            onChange={e => setName(e.target.value)}
            label="Name"
            variant="outlined"
            fullWidth
          />
          <FormLabel component="legend">Companies</FormLabel>
          <RadioGroup
            row
            name="gender1"
            value={type}
            onChange={e => setType(e.target.value)}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel
              value="s&p500"
              control={<Radio />}
              label="S&P 500"
            />
            <FormControlLabel value="tech" control={<Radio />} label="Tech" />
            <FormControlLabel
              value="automotive"
              control={<Radio />}
              label="Automotive"
            />
          </RadioGroup>
          <TextField
            onChange={e => setFee(e.target.value)}
            label="Fee"
            variant="outlined"
            type="number"
            fullWidth
          />
          <Button
            onClick={() => {
              createGame(name, type, fee);
              handleClose();
            }}
          >
            Create
          </Button>
        </div>
      </Modal>
      <Button className={classes.createPrivateGameButton} onClick={handleOpen}>
        Create Private Game
      </Button>
    </div>
  );
}

export default CreatePrivateGame;
