import React from "react";
import { AuthContext } from "../Auth";
import { DashboardServiceProvider } from "./DashboardService";
import { Grid } from "@material-ui/core";
import Header from "./../Header";
import CreatePrivateGame from "./CreatePrivateGame";
import PublicGames from "./PublicGames";
import JoinedGames from "./JoinedGames";

import "./Dashboard.css";

function Dashboard() {
  const { user, logout } = React.useContext(AuthContext);

  return (
    <div className="dashboardWrapper">
      <DashboardServiceProvider>
        <Grid container>
          <Grid container>
            <Header username={user.username} onLogout={logout} />
          </Grid>
          <Grid container>
            <JoinedGames />
          </Grid>
          <Grid container>
            <PublicGames />
          </Grid>
          <CreatePrivateGame />
        </Grid>
      </DashboardServiceProvider>
    </div>
  );
}

export default Dashboard;
