import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { GameServiceContext } from "./GameService";

function Leaderboard() {
  const { leaderboard } = React.useContext(GameServiceContext);
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Win</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaderboard.map((l, idx) => (
            <TableRow key={idx}>
              <TableCell>
                <AccountCircleIcon />#{idx + 1} {l.name}
              </TableCell>
              <TableCell>{l.win}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Leaderboard;
