import React from "react";
import { GameServiceContext } from "./GameService";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Modal,
} from "@material-ui/core";
import "./Portofolio.css";

import { makeStyles } from "@material-ui/core/styles";
import BuyPortfolioModal from "./BuyPortfolioModal";
import CompaniesSelectModal from "./CompaniesSelectModal";
import PortofolioItem from "./PortfolioItem";

function Portofolios() {
  const {
    loading,
    portfolios,
    companies,
    addToPortofolio,
    removeFromPortofolio,
    buyPortfolio,
  } = React.useContext(GameServiceContext);
  const [isBuyPortofolioModalOpen, setIsBuyPortfolioModalOpen] = React.useState(
    false
  );
  const [companiesModalIsOpened, setCompaniesModalIsOpened] = React.useState(
    false
  );
  const [current_portfolio, setCurrentPortfolio] = React.useState(null);
  const portfolios_array = Object.keys(portfolios).map((k) => ({
    name: k,
    content: portfolios[k].content,
  }));

  React.useEffect(() => {
    // current_portfolio is set on a button click so an update in portfolios
    // would not propagate to current_portfolio
    // use this effect to update current_portfolio when portfolios change
    if (current_portfolio != null)
      setCurrentPortfolio({
        ...portfolios[current_portfolio.name],
        name: current_portfolio.name,
      });
  }, [portfolios]);

  if (loading) {
    return <div className="animated-background"></div>;
  }

  console.log(portfolios_array, companies);

  return (
    <Grid container>
      {portfolios_array.length == 0 && (
        <p className="message-area">
          You don`t have any portfolio in this game. You need to buy at least
          one.
        </p>
      )}
      {portfolios_array.length > 0 &&
        portfolios_array.map((portofolio) => (
          <Grid container key={portofolio.name}>
            <h2>{portofolio.name}</h2>
            <Grid container spacing={2}>
              {portofolio.content.length === 0 && (
                <p className="message-area">
                  <b>{portofolio.name} portofolio is empty.</b>
                </p>
              )}
              {portofolio.content.length !== 0 && (
                <p className="message-area">
                  You can add/remove companies in your portofolio untill tha
                  game starts.
                </p>
              )}
              <div className="portfolioItems-container">
                {portofolio.content.map((p, idx) => (
                  <Grid key={idx} item xs={3} className="portfolioItem">
                    <PortofolioItem
                      item={{ ...p, img: companies[p.name].img }}
                      btnText="Remove"
                      onClick={() => {
                        removeFromPortofolio(p.name, portofolio);
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={3} className="portfolioItem portfolioItemAdd">
                  <PortofolioItem
                    item={{
                      img:
                        "https://image.flaticon.com/icons/svg/1104/1104261.svg",
                      name: "Add Company",
                      percentage: "Remaining 50",
                    }}
                    btnText="Click To Add"
                    onClick={() => {
                      setCompaniesModalIsOpened(true);
                      setCurrentPortfolio(portofolio);
                    }}
                  />
                </Grid>
              </div>
            </Grid>
          </Grid>
        ))}

      {false && (
        <Button
          onClick={() => {
            setIsBuyPortfolioModalOpen(true);
          }}
        >
          Buy new portofolio
        </Button>
      )}

      <BuyPortfolioModal
        handleClose={() => setIsBuyPortfolioModalOpen(false)}
        open={isBuyPortofolioModalOpen}
        buyPortfolio={(name) => {
          buyPortfolio(name);
          setIsBuyPortfolioModalOpen(false);
        }}
      />
      {current_portfolio != null && (
        <CompaniesSelectModal
          open={companiesModalIsOpened}
          setCompaniesModalIsOpened={setCompaniesModalIsOpened}
          companies={Object.keys(companies)
            .filter(
              (k) =>
                current_portfolio.content
                  .map((c) => c.name)
                  .indexOf(companies[k].ticker) === -1
            )
            .reduce((acc, k) => {
              acc[k] = companies[k];
              return acc;
            }, {})}
          addToPortofolio={(name, percentage) => {
            addToPortofolio(name, percentage, current_portfolio);
          }}
        />
      )}
    </Grid>
  );
}

export default Portofolios;
