import React from "react";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import "./Header.css";

function Header({ username, onLogout }) {
  const history = useHistory();
  const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState();

  return (
    <div className="headerWrapper">
      <div className="logo" onClick={_ => history.push("/")}>
        <h1>FantasyPi</h1>
      </div>
      <div
        className="username"
        aria-owns={anchorEl}
        onClick={e => {
          setProfileMenuOpen(true);
          setAnchorEl(e.currentTarget);
        }}
      >
        {username}
        <br />
        Account Value: 2000$
        <ArrowDropDownIcon />
      </div>
      <Menu
        open={profileMenuOpen}
        anchorEl={anchorEl}
        onClose={e => {
          setProfileMenuOpen(false);
          setAnchorEl(undefined);
        }}
      >
        <MenuItem disabled>Profile</MenuItem>
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

export default Header;
